<template>
  <div class="wrapper" v-click-outside="focusOut">
    <FloatLabel :label="label" :optional="optional">
    <div :class="[`${error ? 'invalid' : ''}`]" class="input-wrapper d-flex justify-content-between align-items-center">
      <input
        class="shadow-none form-control autocomplete-custom"
        v-model.trim="inputValue"
        :name="name"
        :placeholder="placeholder"
        autocomplete="off"
        ref="autocomplete"
        :class="[`${error ? 'invalid' : ''}`]"
        @focus="initAutocomplete"
        required
        @input="handleOnInput"
      />
    </div>
    </FloatLabel>
    <div v-html="error ? helperText : ''" class="fs-12 text-left mb-2 mt-n3 pr-2 err-text"></div>
  </div>
</template>

<script>
import { logError } from "@/helper/utils";
export default {
  name: 'GooglePlaceAutocomplete',
  components: { 
    FloatLabel: () => import('./FloatLabel.vue')
  },
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  data() {
    return {
      autocomplete: '',
      inputValue: this.selectValue,
    }
  },
  props: {
    selectValue: {
      type: [Number, String],
      default: () => ""
    },
    label: String,
    name: {
      type: String,
      default: () => ""
    },
    error: Boolean,
    isFocus: Boolean,
    optional: Boolean,
    placeholder: String,
    helperText: String,
  },
  methods: {
    initAutocomplete() {
      this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs['autocomplete'], {
        componentRestrictions: {
          country: ['au'],
        },
        fields: ['address_components'],
        types: ['locality', 'street_address', 'postal_code', 'postal_code_prefix'],
        // types: ['geocode', 'establishment'],
        // fields: ["address_components", "geometry"],
        // types: ["address"],
      });
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
    fillInAddress() {
      try {
        const place = this.autocomplete.getPlace();
        if (place?.address_components) {
          const postalCode = place.address_components.find(
            (component) => component.types.includes('postal_code')
          );
          const state = place.address_components.find(
            (component) => component.types.includes('administrative_area_level_1')
          );
  
          const locality = place.address_components.find(
            (component) => component.types.includes('locality')
          );
          let address = [
            locality?.long_name,
            state?.short_name,
            postalCode?.long_name,
          ]
            .filter(Boolean)
            .join(" ");
          this.inputValue = address;
          this.focusOut();
        } else {
          this.inputValue = this.$refs['autocomplete'].value.replace(', Australia', '');
          this.focusOut();
        }
      } catch (error) {
        logError(`Google place API error: ${error}`);
      }
    },
    focusOut() {
      this.$emit('change', this.inputValue);
    },
    handleOnInput() {
      this.$emit('input', this.inputValue);
    },
  },
  mounted() {
    this.initAutocomplete();
  },
}
</script>
<style lang="scss">
.pac-container {
  z-index: 100002;
  box-shadow: 0px 0px 1px 0px #bebebe !important;
}
.pac-item {
  padding: 5px;
  cursor: pointer;
}
</style>